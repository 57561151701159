<template>
    <div style="background: #ffffff;
        padding: 1.5rem 15px;
        position: relative;
        color: #444;" class="content-box">
        <div>
            <span class="d-inline-block border-3left-f20 text-indent-20 color-0 fz-24">{{title}}</span>           
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: String
    },
    created () {
    }
}
</script>
