<template>
    <contentBox title="系统规则查看器">
        <div class="m_t">
            <span>规则名称：</span> <span>{{query.rule_name}}</span>
        </div>
       
        <div class="m_t">
            <span>
                类型：
                <span style="visibility: hidden;">号号</span>
            </span>
            <span>
                {{kindof[query.rule_category]}}
            </span>
        </div>
        <div class="m_t" style="display: flex; justify-content:space-between;">
            <div style="width:49%;">
                公式：
            </div>
            <div style="width: 49%;">
                描述：
            </div>
        </div>
        <div class="m_t" :style="{display: 'flex', justifyContent:'space-between', height: dec_height +'px'}">
            <div style="width:49%;height:100%;background:#eff3f7; overflow: auto;">
                <div style="overfllow-y: auto;">
                    {{query.rule_desc}}
                </div>
            </div>
            <div style="width: 49%;height:100%;">
                <div style="height:49%;background:#eff3f7;overflow: auto;">
                    <div  style="overfllow-y: auto;">
                            {{query.rule_pass}}
                    </div>
                </div>
                <div  style="height:49%;background:#eff3f7;overflow-y: auto; margin-top: 2%">
                    <div  style="">
                        {{query.rule_notpass}}
                    </div>
                </div>
            </div>
        </div>
    </contentBox>
</template>
<script>
export default {
     components: {
        contentBox: require('@/components/centerCom/contentBox.vue').default
    },
    data () {
        return {
            query: {},
            kindof: ['资产类', '收入类', '成本类', '费用类', '现金流类'],
            dec_height: $(window).height() - 60 - 48 - 24 -36 - 99 -10 - 48
        }
    },
    created() {
        this.query = this.$router.history.current.query
    }
}
</script>
<style lang="less" scoped>
.m_t{
    margin-top: 10px;
}
</style>

